import { lazy } from "react";
const Friends = lazy(() => import("../pages/Friends"));
const Home = lazy(() => import("../pages/Home"));
const Play = lazy(() => import("../pages/Play"));
const Profile = lazy(() => import("../pages/Profile"));

const coreRoutes = [
	{ path: "/home", title: "Home", component: Profile },
	{
		path: "/play",
		title: "Play",
		component: Play,
	},
	{
		path: "/friends",
		title: "Friends",
		component: Friends,
	},
	{ path: "/profile", title: "Profile", component: Profile },
];

const routes = [...coreRoutes];
export default routes;
