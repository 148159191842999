// This file contains the error constants
// which ensures we avoid duplicating similar
// error messages in different parts of the
// application. Use these error texts.

import { errConstants as errorConstants } from './errorConstants.js';
export const errConstants = {
  EXPIRED_SESSION: {
    type: 'EXPIRED_SESSION',
    message: 'Your session has expired, please go back and log in',
    buttonText: 'Go back to Login',
    buttonAction: 'LOGIN',
  },

  RESOURCES_UNAVAILABLE: {
    type: 'RESOURCES_UNAVAILABLE',
    message: 'Resources unavailable',
    buttonText: 'Reload',
    buttonAction: 'RELOAD',
  },
  UNKNOWN_ERROR: {
    type: 'UNKNOWN_ERROR',
    message: 'Unknown error occurred, please try again',
    buttonText: 'Reload',
    buttonAction: 'GOBACK',
  },
  UNAUTHORIZED: {
    type: 'UNAUTHORIZED',
    message: 'Your session has expired, please go back and log in again',
    buttonText: 'Go back to Login',
    buttonAction: 'LOGIN',
  },
  NOT_FOUND: {
    type: 'NOT_FOUND',
    message: 'The resource you are looking for does not exist. ',
    buttonText: 'Go back',
    buttonAction: 'LOGIN',
  },
  BAD_REQUEST: {
    type: 'BAD_REQUEST',
    message: 'Something is wrong with your request, please try again',
    buttonText: 'Reload',
    buttonAction: 'RELOAD',
  },
  FORBIDDEN: {
    type: 'FORBIDDEN',
    message: 'Your session has expired, please go back and log in again',
    buttonText: 'Go back',
    buttonAction: 'LOGIN',
  },
  INTERNAL_SERVER_ERROR: {
    type: 'INTERNAL_SERVER_ERROR',
    message: 'Internal server error',
    buttonText: 'Reload',
    buttonAction: 'RELOAD',
  },
};
export const blockingErrorConstants = [
  errorConstants.EXPIRED_SESSION.type,
  // errorConstants.UNKNOWN_ERROR.type,
  errorConstants.UNAUTHORIZED.type,
  errorConstants.FORBIDDEN.type,
];
