import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import errorReducer from "./errorSlice";
import userReducer from "./userSlice";
import clientidReducer from "./clientidSlice";
import searchReducer from "./searchSlice";
import languageReducer from "./languageSlice";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer, persistStore } from "redux-persist";
import { root } from "postcss";
const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	clientid: clientidReducer,
	search: searchReducer,
	error: errorReducer,
	language: languageReducer,
});

// const persistConfig = {
//   key: 'root',
//   storage,
//   blacklist: ['auth', 'user', 'error'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const loggerMiddleware = (store) => (next) => (action) => {
	if (!action.type) {
		return next(action);
	}
	console.log("type", action.type);
	console.log("payload", action.payload);
	console.log("currentState: ", store.getState());

	next(action);

	console.log("next state: ", store.getState());
};

// const middlewares = [loggerMiddleware];
const middlewares = [];

export const store = configureStore({
	reducer: rootReducer,

	devTools: process.env.NODE_ENV !== "production",
	middleware: (getDefaultMiddleware) => [
		...middlewares,
		...getDefaultMiddleware(),
	],
});

// export const persistor = persistStore(store);
