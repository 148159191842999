import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import i18n from "./i18n"; // Import the i18n configuration
import { I18nextProvider } from "react-i18next";

import App from "./App";
import "./index.css";
import "./satoshi.css";
// import { PersistGate } from 'redux-persist/integration/react';

ReactDOM.createRoot(document.getElementById("root")).render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<Provider store={store}>
				<Router>
					<App />
				</Router>
			</Provider>
		</I18nextProvider>
	</React.StrictMode>
);
