import React, { useRef, useEffect } from "react";
import "../i18n"; // Import the i18n configuration
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import CardOne from "../components/CardOne";
const menu = [
	{
		id: 1,
		title: "Pick a quest",
	},
	{
		id: 2,
		title: "Create your own quest",
	},
	{
		id: 3,
		title: "Pick a quest level",
		questLevel: [
			{
				id: 1,
				title: "Easy",
			},
			{
				id: 2,
				title: "Medium",
			},
			{
				id: 3,
				title: "Hard",
			},
		],
	},
];

const quests = [
	{
		name: "Quest 1",
		description: "Description 1",
		questions: [
			{
				question: "Question 1",
				answer: "Answer 1",
			},
			{
				question: "Question 2",
				answer: "Answer 2",
			},
		],
	},
	{
		name: "Quest 2",
		description: "Description 2",
		questions: [
			{
				question: "Question 1",
				answer: "Answer 1",
			},
			{
				question: "Question 2",
				answer: "Answer 2",
			},
		],
	},
	{
		name: "Quest 3",
		description: "Description 3",
		questions: [
			{
				question: "Question 1",
				answer: "Answer 1",
			},
			{
				question: "Question 2",
				answer: "Answer 2",
			},
		],
	},
	{
		name: "Quest 4",
		description: "Description 4",
		questions: [
			{
				question: "Question 1",
				answer: "Answer 1",
			},
			{
				question: "Question 2",
				answer: "Answer 2",
			},
		],
	},
];

const Home = () => {
	const { t, i18n } = useTranslation();
	// console.log(t(i18n.language));
	// const language = useSelector((state) => state.language.language);

	// useEffect(() => {
	// 	console.log(language);
	// 	i18n.changeLanguage(language); // Update i18next language when Redux language changes
	// }, [language, i18n]);

	return (
		<>
			<div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
				<div className="px-4 py-6 text-center lg:pb-8 xl:pb-11.5">
					<div className="flex flex-col justify-center items-center px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
						<h3 className="text-2xl py-4">{t("pick a quest")}</h3>

						<div className="grid 2xsm:grid-cols-2 2xsm:gap-4 md:grid-cols-4">
							{quests.map((quest, i) => (
								<div key={i} className="min-w-[300px] 2xsm:min-w-[40px]">
									<CardOne name={t(quest.name)} />
								</div>
							))}
						</div>

						<h3 className="text-2xl py-4">{t("create your own quest")}</h3>
						<button
							type="button"
							className="rounded-md border text-pink border-stroke bg-primary hover:bg-[#feeff2] hover:-translate-y-1 hover:scale-100 py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark"
						>
							{t("start")}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
