import { useDispatch, useSelector } from "react-redux";
import { clearError, selectError } from "../../redux/errorSlice";
import { userLogout } from "../../redux/authActions";
import { errConstants } from "../../constants/errorConstants";
import { getErrorText } from "../../js/utils/errors";
import { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { clearCookie } from "../../js/utils/cookies";
export default function ErrorPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const error = useSelector(selectError);
	const errText = getErrorText(error);

	const buttonAction = () => {
		console.log("error", error);
		switch (error?.buttonAction) {
			case "RELOAD":
				navigate("/home");
				break;
			case "LOGIN":
				dispatch(clearError());
				dispatch(userLogout());
				clearCookie("sessionID");
				navigate("/");
				break;
			case "GOBACK":
				navigate(-1);
				break;
			default:
				navigate("/home");
				break;
		}
	};

	useEffect(() => {
		error ===
			(errConstants.EXPIRED_SESSION ||
				errConstants.FORBIDDEN ||
				errConstants.UNAUTHORIZED) && dispatch(userLogout());
		console.log("error in errorpage", error);
	}, []);
	return (
		// <div id="error-page" className="flex flex-col items-center">
		//   <h2>Error</h2>
		//   <p>
		//     <i>{errText?.main}</i>
		//   </p>
		//   <p>
		//     <i>{errText?.secondary}</i>
		//   </p>
		//   <div className="mb-5 pt-5">
		//     <input
		//       type="submit"
		//       value={errText?.buttonText}
		//       className={`w-full cursor-pointer rounded-lg border border-primary bg-primary p-4 text-white transition hover:bg-opacity-90`}
		//       onClick={() => errText?.buttonAction()}
		//     />
		//   </div>
		// </div>
		<>
			<section className="relative z-10 bg-primary py-[120px]">
				<div className="container mx-auto">
					<div className="-mx-4 flex">
						<div className="w-full px-4">
							<div className="mx-auto max-w-[450px] text-center">
								<h2 className="mb-2 text-[50px] font-bold leading-none text-white sm:text-[80px] md:text-[100px]">
									Error
								</h2>
								<h4 className="mb-3 text-[22px] font-semibold leading-tight text-white">
									Oops! Something went wrong.
								</h4>

								{/* <p className="mb-8 text-lg text-white">{error.tsype}</p> */}
								<p className="mb-8 text-lg text-white">
									{error ? error.message : ""}
								</p>
							</div>
							<div className="flex justify-center mb-5 pt-5">
								<input
									type="submit"
									value={error ? error.buttonText : "Go back"}
									className={`w-1/2 cursor-pointer rounded-lg border border-white bg-primary p-4 text-white transition hover:bg-opacity-90`}
									onClick={() => {
										buttonAction();
									}}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="absolute left-0 top-0 -z-10 flex h-full w-full items-center justify-between space-x-5 md:space-x-8 lg:space-x-14">
					<div className="h-full w-1/3 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
					<div className="flex h-full w-1/3">
						<div className="h-full w-1/2 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
						<div className="h-full w-1/2 bg-gradient-to-t from-[#FFFFFF14] to-[#C4C4C400]"></div>
					</div>
					<div className="h-full w-1/3 bg-gradient-to-b from-[#FFFFFF14] to-[#C4C4C400]"></div>
				</div>
			</section>
		</>
	);
}
