import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { clearCookie, getCookieValue } from '../js/utils/cookies';
import authReducers from './authSlice';
export const devURL = 'http://127.0.0.1:5173';
export const prodURL = 'http://localhost:8888';
// export const prodURL = 'https://192.168.30.43:8888';
import { apiUrl } from '../constants/apiConfig';
import { setError } from './errorSlice';
import { errConstants } from '../constants/errorConstants';
import { handleError } from '../js/utils/errors';

export const userLogin = createAsyncThunk(
  'auth/login',
  async ({ username, password, navigate }, { rejectWithValue }) => {
    window.sessionStorage.removeItem('previous-session');
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      clearCookie('sessionID');
      // let response = null;
      // await new Promise(async (resolve) => {
      const response = await axios.post(
        // TODO: the toast wouldn't show up if this part returns a promise.
        // `${devURL}/login`,
        `${apiUrl}/login`,
        // `${apiUrl}/login`,
        { username, password },
        config,
      );
      //   response = res;
      //   return resolve(res);
      // });
      console.log('response payload', response);
      document.cookie = `sessionID=${response.data.result.sessionid}; path=/; Secure; SameSite=Strict;`;
      window.sessionStorage.setItem('admin', response.data.result.is_admin);
      const resources = await axios.post(
        `${apiUrl}/getagentresources`,
        { agentid: response.data.result.agentid },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${response.data.result.sessionid}`,
          },
        },
      );

      console.log('resources', resources);

      navigate('/dashboard');
      // if the logged in user only has 1 resource, redirect the user to that resource first
      if (resources.data.result.rows.length === 1) {
        window.open(
          `https://webmd02.themanualdialcompany.com/textmanagerhub.wr?sidtoken=dgn1zgn1zfmbv6rbv5p9w6p9v6nct8ob&resourcenum=90092`,
          '_blank',
        );
      }

      return response.data;
    } catch (err) {
      // return custom error message from backend if present
      console.log('error', err.response.status);
      if (
        err.response.status === 400 ||
        err.response.status === 401 ||
        err.response.status === 403 ||
        err.response.status === 404
      ) {
        return rejectWithValue(err.response.data.message);
      } else if (err.response.status === 500) {
        return rejectWithValue(err.request.statusText);
      }
    }
  },
);

export const userLogout = createAsyncThunk(
  'auth/logout',
  async (navigate, { rejectWithValue }) => {
    try {
      const sessionID = getCookieValue('sessionID');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionID}`,
        },
      };
      const response = await axios.get(
        // `${devURL}/logout`,
        `${apiUrl}/logout`,
        // `${apiUrl}/logout`,
        config,
      );

      clearCookie('sessionID');
      window.sessionStorage.removeItem('clientids');
      window.sessionStorage.removeItem('admin');
      navigate(`/`);
      return response.data;
    } catch (err) {
      // return custom error message from backend if present
      if (
        err.response.status === 401 ||
        err.response.status === 403 ||
        err.response.status === 400
      ) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.message);
      }
    } finally {
      navigate(`/`);
    }
    s;
  },
);
