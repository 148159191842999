import { createSlice } from '@reduxjs/toolkit';
import { userLogin, userLogout } from './authActions';
import { getCookieValue } from '../js/utils/cookies';
import { fetchUser } from './userSlice';

const sessionID = getCookieValue('sessionID');

const initialState = {
  loading: false,
  userInfo: null, // for user object
  sessionId: null, // for storing the JWT
  isAdmin: false, // for admin
  error: null,
  success: false, // for monit
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    // login: (state, action) => {
    //   state.user = action.payload;
    // },
    // logout: (state) => {
    //   state.user = null;
    // },
  },
  extraReducers: (builder) => {
    // handle async actions
    // login user
    builder
      // Login user
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.result;
        state.sessionId = payload.result.session;
        state.isAdmin = payload.result.is_admin > 0;
        // // Dispatch the currentUser action
        // dispatch(authSlice.actions.currentUser(payload));
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Logout user
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogout.fulfilled, (state) => {
        state.loading = false;
        state.success = true; // Logout successful
      })
      .addCase(userLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

export const selectLoginError = (state) => state.auth.error;
export const selectIsAdmin = (state) => state.auth.isAdmin;
export default authSlice.reducer;
