// function to get the cookie value
export function getCookieValue(cookieName) {
  const cookies = document.cookie.split('; ');
  // console.log('current cookies', cookies);
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    // console.log('cookie name', name);
    if (name === cookieName) {
      return value;
    }
  }
  return '';
}

export function clearCookie(name) {
  const cookies = document.cookie.split(';');
  console.log('cookies', cookies);
  for (const coockie of cookies) {
    const [cookieName, value] = coockie.split('=');
    console.log('cookieName', cookieName.trim());

    if (cookieName.trim() === name) {
      // Get the current path and split it into its components
      const pathParts = window.location.pathname.split('/');

      // Clear the cookie for each path component
      for (let i = 0; i < pathParts.length; i++) {
        const path = pathParts.slice(0, i).join('/') || '/';
        document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
      }
      // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/ `;
      // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/dashboard/admin `;
      // document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/dashboard/admin `;
    }
  }
}
