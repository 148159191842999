import { Suspense, lazy, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import SignIn from "./pages/Authentication/SignIn";
// import SignUp from "./pages/Authentication/SignUp";
// import ForgotPassword from "./pages/Authentication/ForgotPassword";
import ErrorPage from "./pages/Error/ErrorPage";
import Loader from "./common/Loader";
// import { selectCurrentUser } from "./redux/userSlice";
import routes from "./routes";
// import { getCookieValue, clearCookie } from "./js/utils/cookies";
import Home from "./pages/Home";
import { setLanguage } from "./redux/languageSlice";
// import { clearError } from "./redux/errorSlice";
// import UpdatePassword from "./pages/Authentication/UpdatePassword";
// import { ToastContainer } from "react-toastify";

// import Steps from './pages/Steps';
// import Berts from './pages/Berts';
// import Substeps from './pages/Substeps';
// import Calendar from './pages/Calendar';
// import Redirect from './pages/Redirect';
const Dashboardlayout = lazy(() => import("./layout/DashboardLayout"));

function App() {
	const [loading, setLoading] = useState(true);
	// const dispatch = useDispatch();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation(); // Destructure the translation function `t`
	// const currentUser = useSelector(selectCurrentUser);
	// const pageLoading = useSelector((state) => state.auth.loading);

	// const session = getCookieValue("sessionID");
	// clear the error when the pathname is not /error
	// TODO: try to get a component will unmount to work
	// in place of this check

	// if (window.location.pathname !== "/error") {
	// 	dispatch(clearError());
	// }
	useEffect(() => {
		setTimeout(() => setLoading(false), 500);
		// const savedLanguage = localStorage.getItem("language");
		const savedLanguage = navigator.language.split("-")[0];
		console.log(savedLanguage);
		dispatch(setLanguage(savedLanguage));
		// if (savedLanguage === "zh") {
		// 	dispatch(setLanguage("cn"));
		// } else {
		// 	dispatch(setLanguage("en"));
		// }

		// if (session) {
		navigate("/home");
		// }
	}, [dispatch]);

	return loading ? (
		<Loader />
	) : (
		<>
			{/* <ToastContainer limit={3} /> */}
			<Routes>
				{/* <Route index element={<SignIn />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/forgot-password/:token" element={<ForgotPassword />} />
				<Route path="/update-password" element={<UpdatePassword />} /> */}
				{/* {session && ( */}
				<Route element={<Dashboardlayout />}>
					<Route path="/home" element={<Home />} />
					{/* <Route path="/error" element={<ErrorPage />} /> */}
					{routes.map((routes, index) => {
						const { path, component: Component } = routes;
						return (
							<Route
								key={index}
								path={`/home${path}`}
								element={
									<Suspense fallback={<Loader />}>
										<Component />
									</Suspense>
								}
							/>
						);
					})}
				</Route>
				{/* )} */}
				{/* Add the fallback ErrorPage route */}å
				<Route path="*" element={<ErrorPage />} />
			</Routes>
		</>
	);
}

export default App;
