import { createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";

const languageSlice = createSlice({
	name: "language",
	initialState: { language: i18n.language }, // use detected language from i18next
	reducers: {
		setLanguage: (state, action) => {
			state.language = action.payload;
			i18n.changeLanguage(action.payload); // update i18n language
			localStorage.setItem("language", action.payload); // Save preference to localStorage
		},
	},
});

export const { setLanguage } = languageSlice.actions;
export default languageSlice.reducer;
