// userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCookieValue } from '../js/utils/cookies';
import axios from 'axios';
import { devURL, prodURL } from './authActions';
import { apiUrl } from '../constants/apiConfig';

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const sessionID = getCookieValue('sessionID');
      console.log('sessionID to get user', sessionID);
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${sessionID}`,
        },
      };
      const response = await axios.get(
        // `${devURL}/getuser`,
        `${apiUrl}/getuser`,
        // `${apiUrl}/getuser`,
        config,
      );
      console.log('user data from getuser', response.data.result);
      return response.data.result;
    } catch (err) {
      //return custom error message from backend if present
      if (
        err.response.status === 400 ||
        err.response.status === 401 ||
        err.response.status === 403
      ) {
        return rejectWithValue(err.response.data.message);
      } else {
        return rejectWithValue(err.request.statusText);
      }
    }
  },
);
const initialState = {
  currentUser: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    clearCurrentUser: (state) => {
      state.currentUser = null;
    },
  },
  extraReducers: (builder) => {
    // Handle the pending, fulfilled, and rejected states of the async thunk
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.error = null;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.loading = false;
      state.currentUser = null;
      state.error = action.error.message;
    });
  },
});

export const { setCurrentUser, clearCurrentUser } = userSlice.actions;

export const selectCurrentUser = (state) => state.user.currentUser;

export const selectLoading = (state) => state.user.loading;

export const selectError = (state) => state.user.error;

export default userSlice.reducer;
