// This function will be used to handle the error object
// and return the error constant to set the error in state
import { useNavigate } from 'react-router-dom';
import { errConstants } from '../../constants/errorConstants';
import { isRejected, isRejectedWithValue } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

export function handleError(errObject) {
  /**
   * This function will handle the errorObejct from the axios request:
   * here, errObject is AxiosError, it looks like this:
   * {errObject: AxiosError}
   *
   */
  const path = errObject?.response?.request?.responseURL?.split('/').pop(); // e.g.  'https://talkingais.com/getsteps' => 'getsteps'

  if (
    errObject?.response?.status === 400 ||
    errObject?.response?.status === 401 ||
    errObject?.response?.status === 403
  ) {
    return errConstants.EXPIRED_SESSION;
  } else if (errObject?.response?.status === 500 && path === 'getresources') {
    return errConstants.RESOURCES_UNAVAILABLE;
  } else {
    return errConstants.UNKNOWN_ERROR;
  }
}

export function getErrorText(errCode) {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate('/');
  };
  const handleReloadClick = () => {
    navigate('/dashboard');
  };
  switch (errCode) {
    case errConstants.EXPIRED_SESSION:
      return {
        main: 'Your session has expired.',
        secondary: 'Please login again.',
        buttonAction: handleLoginClick,
        buttonText: 'Go to Login',
      };
    case errConstants.UNKNOWN_ERROR:
      return {
        main: 'An unknown error has occurred.',
        secondary: 'Please try to reload.',
        buttonAction: handleGoBackClick,
        buttonText: 'Reload',
      };
  }
}
export function getToastText(errCode) {
  switch (errCode) {
    case errConstants.RESOURCES_UNAVAILABLE:
      return {
        main: 'Resources are currently unavailable.',
        secondary: 'Please try again later.',
      };
  }
}

export const handleErrors = (err) => {
  let error = errConstants.UNKNOWN_ERROR;
  switch (err?.response?.status) {
    case 400:
      return errConstants.BAD_REQUEST;
    case 401:
      return errConstants.UNAUTHORIZED;
    case 403:
      return errConstants.FORBIDDEN;
    case 404:
      return errConstants.NOT_FOUND;
    case 500:
      return errConstants.INTERNAL_SERVER_ERROR;
    default:
      return errConstants.UNKNOWN_ERROR;
  }
};

export function handleLoginClick() {
  const navigate = useNavigate();
  navigate('/');
}
export function handleReloadClick() {
  const navigate = useNavigate();
  navigate('/dashboard');
}

export function handleGoBackClick() {
  const navigate = useNavigate();
  navigate(-1);
}

export const showErrorToast = (name, message) => {
  toast.error(name, {
    data: {
      title: name,
      text: message,
    },
  });
};
