/**
 * This file contains the configuration for the API URL.
 * The URL is different for development and production.
 * The URL is set in the .env file.
 */

const devURL = import.meta.env.VITE_DEV_URL;
const prodURL = import.meta.env.VITE_PROD_URL;

const apiUrl = import.meta.env.DEV ? devURL : prodURL;

export { apiUrl };
