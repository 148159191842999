import React from "react";

const CardOne = ({ name, onClick }) => {
	return (
		<div
			className="rounded-md border border-stroke bg-primary hover:bg-[#feeff2] hover:-translate-y-1 hover:scale-100  py-6 px-7.5 shadow-default dark:border-strokedark dark:bg-boxdark cursor-pointer"
			onClick={onClick}
		>
			<div className="mt-4 flex items-center justify-center w-full h-10 ">
				<div className="text-center">
					<h4 className="text-title-md font-bold text-pink">{name}</h4>
				</div>
			</div>
		</div>
	);
};

export default CardOne;
