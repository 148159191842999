// errorSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientid: null,
};

const clientidSlice = createSlice({
  name: 'clientid',
  initialState,
  reducers: {
    setClientid: (state, action) => {
      state.clientid = action.payload;
    },
  },
});

export const { setClientid } = clientidSlice.actions;

export const selectClientid = (state) => state.clientid.clientid;

export default clientidSlice.reducer;
