import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import en from "./locales/en/translation.json";
import zh from "./locales/zh/translation.json";
const fallbackLng = ["en"];

i18n
	// .use(HttpApi) // loads translations from your server
	.use(LanguageDetector) // Auto-detects the user's language
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en: { translation: en },
			zh: { translation: zh },
		},
		fallbackLng, // default language
		// detection: {
		// 	checkWhitelist: true,
		// 	// Options for language detection
		// 	order: ["localStorage", "navigator", "htmlTag"], // Check these sources in order
		// 	caches: ["localStorage"], // Cache the detected language in localStorage
		// },
		debug: false,
		interpolation: {
			escapeValue: false, // no need for react. it escapes by default
		},
		// backend: {
		// 	loadPath: "/locales/{{lng}}/translation.json",
		// },
	});

export default i18n;
